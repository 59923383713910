import React from 'react'
import Layout from '../components/Layout'
import Helmet from 'react-helmet'
import { StaticImage } from 'gatsby-plugin-image'
const backendError = () => {
  return (
    <Layout>
      <Helmet>
        <meta name="robots" content="noindex" />
        <meta name="robots" content="nofollow" />
      </Helmet>
      <section className="se-section text-center padding-404">
        <div className="container-1">
          <div className="row">
            <div className="col-md-12">
              <h1 className="f-45 h1-404-color">Ooops!</h1>
              <StaticImage
                className="w-50 mx-auto"
                alt="404"
                src="../resources/images/vectors/undraw_startled_8p0r.svg"
                placeholder="tracedSVG"
              />
            </div>
            <div className="col-md-10 offset-md-1">
              <p className="hero-description-dark mt-4">
                Backend service unreachable, contact{' '}
                <a href="mailto:info@lenses.io" id="contactus-link-infoemail">
                  info@lenses.io
                </a>
                ?
                <br />
                <br />
                Or return to <a href="https://lenses.io">homepage</a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default backendError
